import { Paper, Typography } from '@material-ui/core'
import React from 'react'

function BaseFooter() {
    return (
        <>
            <br />
            <br />
            <Paper square  elevation={0} >
                <Typography component='a' href="https://about.touristerguide.com" target="_blank" variant="body1" color="inherit" >
                    Powered by Touristerguide
                </Typography>
            </Paper>
        </>
    )
}

export default BaseFooter
